import axios, { AxiosError, AxiosInstance } from "axios";

import { backendUrl } from "../config";
import { version } from "../../package.json";

type ErrorResponse = {
  type: string;
  message: string;
};

function withVersion(client: AxiosInstance) {
  let alerted = false;

  client.interceptors.request.use(request => {
    return {
      ...request,
      headers: {
        ...request.headers,
        Version: version
      }
    };
  });

  client.interceptors.response.use(
    undefined,
    (error: AxiosError<ErrorResponse>) => {
      if (error?.response?.data?.type === "VersionError" && !alerted) {
        return Promise.resolve(alert(error?.response?.data?.message))
          .then(() => (alerted = true))
          .then(() => navigator.serviceWorker.getRegistration())
          .then(swRegistration => swRegistration.unregister())
          .finally(() => window.location.reload());
      }

      return Promise.reject(error);
    }
  );

  return client;
}

export const client = withVersion(axios.create({ baseURL: backendUrl }));
